<template>

  <div id="wrapperSignUpForm" class="single_layout m-4">

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="firstName"
        name="firstName"
        label="First Name"
        rules="required|firstName"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="lastName"
        name="lastName"
        label="Last Name"
        rules="required|lastName"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="company"
        name="company"
        label="Company Name"
        rules="required|company"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="email"
        name="email"
        label="Email"
        rules="required|email"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputPhone
        ref="phone"
        name="phone"
        label="Phone"
        rules="required|phone"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputCheckbox
        ref="callForWalkthrough"
        name="call_for_walkthrough"
        label="I have questions, please have an expert contact me."
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <button
        :class="buttonStyle"
        @click="handleSubmitClick"
      >
        Submit Form
      </button>
    </div>

  </div>
</template>

<script>

import InputGroup from '@src/components/input-group.vue';
import InputPhone from '@src/components/input-phone.vue';

export default {
  name: 'SignUpLayoutSingle',
  props: {
    onInputChange: { type: Function, required: true },
    onSubmit: { type: Function, required: true },
    onError: { type: Function, required: true },
    buttonStyle: { type: String, required: false, default: 'btn_submit clientpay' },
  },
  components: {
    InputGroup,
    InputPhone,
  },
  methods: {
    handleSubmitClick(event) {
      this.$props.onSubmit(event, this.$refs);
    },
  },

};
</script>
