<template>
  <div id="wrapperTradeShowForm" class="default_layout">

    <h2>Tradeshow Form Mockup</h2>

    <div v-if="errorMessage != ''" class="alert alert-danger form-input-error">
      {{ this.$data.errorMessage }}
    </div>

    <div v-if="showCustomerForm">
      <div class="form_column">
        <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2">
          <InputGroup
            ref="firstName"
            name="firstName"
            label="First Name"
            rules="required"
            :initialValue="this.$data.model.firstName"
            @inputChanged="onInputChange"
          />
          <InputGroup
            ref="lastName"
            name="lastName"
            label="Last Name"
            rules="required"
            :initialValue="this.$data.model.lastName"
            @inputChanged="onInputChange"
          />
      </div>

      <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2">
        <InputGroup
          ref="company"
          name="company"
          label="Company Name"
          rules="required"
          :initialValue="this.$data.model.company"
          @inputChanged="onInputChange"
        />
      </div>

      <div class="grid lg:grid-cols-2 lg:gap-4 grid-cols-1 gap-2">
        <InputGroup
          ref="email"
          name="email"
          label="Email"
          rules="required|email"
          :initialValue="this.$data.model.email"
          @inputChanged="onInputChange"
        />
        <InputPhone
          ref="phone"
          name="phone"
          label="Phone"
          rules="required|phone"
          :initialValue="this.$data.model.phone"
          @inputChanged="onInputChange"
        />
      </div>

      <div class="grid grid-cols-1 lg:gap-4 gap-2">
        <InputGroup
          ref="NumberOfEmployees"
          name="NumberOfEmployees"
          type="number"
          label="Firm Size"
          rules="required"
          :initialValue="this.$data.model.NumberOfEmployees"
          @inputChanged="onInputChange"
        />
        </div>
      </div>
      <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputCheckboxGroup
        ref="Event_Brands__c"
        name="Event_Brands__c"
        label="Brand Interest"
        rules="required"
        :options="[
          'AffiniPay',
          'CASEPeer',
          'ClientPay',
          'CPACharge',
          'Docketwise',
          'LawPay',
          'MyCase',
          'Soluno',
          'Websites',
          'Woodpecker'
        ]"
        @inputChanged="handleCheckboxChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <button
        :class="buttonStyle"
        @click="onCustomerIntake"
      >
        Submit Form
      </button>
    </div>

  </div>

    <div v-if="showSalesIntake">
      <h3>QUALIFYING REP FIELD HERE</h3>
    </div>

    <div v-if="showSalesForm">

      <div class="grid grid-cols-1 lg:gap-4 gap-2">
        <InputSelect
          ref="Event_Lead_Ranking__c"
          name="Event_Lead_Ranking__c"
          label="Ranking"
          rules="required"
          :options="[
            '- Select -',
            'Cold',
            'Warm',
            'Hot'
          ]"
          @inputChanged="onInputChange"
        />
      </div>

      <div class="grid grid-cols-1 lg:gap-4 gap-2">
        <InputTextArea
          ref="Qualification_Notes__c"
          name="Qualification_Notes__c"
          label="Notes / Remarks"
          rules="required"
          @inputChanged="onInputChange"
        />
      </div>

      <div>
        <button :class="buttonStyle" @click="onSalesRepIntakeComplete">Save & Submit</button>

        <button :class="buttonStyle" @click="handleBackClick">Go Back</button>
      </div>
    </div>

    <div v-if="showThankYou">
      <div>
        <h1>Form Submission Complete</h1>
        <p>Thank you for submitting the data</p>

        <button :class="buttonStyle" @click="resetForm">Reset Form</button>
      </div>
    </div>
  </div>

</template>

<script>

import errorReporting from '@src/helpers/error-reporting';
import InputGroup from '@src/components/input-group.vue';
import InputPhone from '@src/components/input-phone.vue';
import InputSelect from '@src/components/input-select.vue';
import InputTextArea from '@src/components/input-textarea.vue';
import InputCheckboxGroup from '@src/components/input-checkbox-group.vue';
import marOpsValidation from '@src/helpers/validation';

export default {
  name: 'TradeShowEventForm',
  props: {
    formId: { type: [String, Number], required: true },
    layout: { type: String, required: false, default: 'default' },
    buttonStyle: { type: String, required: false },
  },
  components: {
    InputGroup,
    InputPhone,
    InputSelect,
    InputTextArea,
    InputCheckboxGroup,
  },
  computed: {
    showCustomerForm() {
      return this.$data.customerIntakeComplete === false;
    },
    showSalesForm() {
      const d = this.$data;
      return d.customerIntakeComplete === true && d.salesRepIntakeComplete === false;
    },
    showThankYou() {
      const d = this.$data;
      return d.customerIntakeComplete === true && d.salesRepIntakeComplete === true;
    },
  },
  data() {
    return {
      model: {
        company: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        NumberOfEmployees: '',
        Event_Brands__c: '',
        Qualification_Notes__c: '',
        Event_Lead_Ranking__c: '',
      },
      errorMessage: '',
      customerIntakeComplete: false,
      salesRepLogged: false,
      salesRepIntakeComplete: false,
    };
  },
  emits: ['onSubmit'],
  methods: {
    onInputChange(target) {
      const key = target.name || '';
      const val = target.value || '';
      if (key !== '' && val !== '') {
        this.$data.model[key] = val;
      }
    },
    handleBackClick() {
      this.$data.customerIntakeComplete = false;
      this.$data.salesRepIntakeComplete = false;
    },
    handleCheckboxChange(data) {
      this.$data.model.Event_Brands__c = data.join();
    },
    onCustomerIntake() {
      const marOpsAllValid = marOpsValidation.validateAll(this.$refs, this.$data.model);
      const mopsErrors = marOpsAllValid.filter((i) => i !== true);

      if (mopsErrors.length === 0) {
        this.$data.errorMessage = '';
        this.$data.customerIntakeComplete = true;
      } else {
        this.$data.errorMessage = 'Required Fields are empty';
      }
    },
    onSalesRepIntakeComplete() {
      this.$data.salesRepIntakeComplete = true;
      this.$data.errorMessage = '';
      this.$emit('onSubmit', this.$data.model, this.$refs);
    },
    onError(err) {
      errorReporting(err);
    },
    resetForm() {
      this.$data.model.firstName = '';
      this.$data.model.lastName = '';
      this.$data.model.company = '';
      this.$data.model.email = '';
      this.$data.model.phone = '';
      this.$data.model.NumberOfEmployees = 1;
      this.$data.model.Event_Brands__c = '';
      this.$data.model.Qualifying_Rep__c = '';
      this.$data.model.Qualifying_Notes__c = '';
      this.$data.model.Event_Lead_Ranking__c = '';
      this.$data.customerIntakeComplete = false;
      this.$data.salesRepIntakeComplete = false;
    },
  },
};
</script>
