<template>
  <div ref="name" :class="inputGroupCss">
    <div class="label_wrap">
      <label :for="name">{{ label }}</label>
    </div>
    <div class="input_wrap">
      <input
        :id="name"
        :name="name"
        type="tel"
        autocomplete="tel"
        v-maska="'(###) ###-####'"
        v-model="value"
        @change="handleChange"
        @blur="validInput"
      />
      <span class="error_message_text">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>

import { maska } from 'maska';
import marOpsValidator from '../helpers/validation';

export default {
  name: 'InputPhone',
  props: {
    name: { type: String, required: true },
    label: { type: String, default: '' },
    type: { type: String, default: 'text' },
    rules: { type: String, default: '' },
    initialValue: { type: String, default: '' },
  },
  directives: {
    maska,
  },
  computed: {
    inputGroupCss() {
      return (this.$data.errorMessage !== '' ? 'input_group form-input-error' : 'input_group');
    },
  },
  data() {
    return {
      value: '',
      errorMessage: '',
    };
  },
  emits: [
    'inputChanged',
  ],
  methods: {
    handleChange(event) {
      this.value = event.target.value;
      this.$emit('inputChanged', event.target);
    },
    validInput(event) {
      const val = event.target.value || '';
      const verifications = this.$props.rules.split('|');
      let msg = true;
      verifications.forEach((validationType) => {
        if (validationType === 'phone') {
          msg = marOpsValidator.validatePhone(val);
        }

        this.errorMessage = (msg === true ? '' : msg);
      });
    },
  },
  mounted() {
    this.$data.value = this.$props.initialValue;
    this.$emit('inputChanged', {
      name: this.$props.name,
      value: this.$props.initialValue,
    });
  },
  watch: {
    value(newVal) {
      this.$emit('inputChanged', {
        name: this.$props.name,
        value: newVal,
      });
    },
  },
};

</script>
