<template>
  <div ref="ContactFormRoot">

    <div v-if="this.$props.layout == 'single'">
      <ContactLayoutSingle
        :onInputChange="onInputChange"
        :onSubmit="onSubmit"
        :onError="onError"
        :buttonStyle="buttonStyle"
      />
    </div>

    <div v-else>
      <ContactLayoutDefault
        :onInputChange="onInputChange"
        :onSubmit="onSubmit"
        :onError="onError"
        :buttonStyle="buttonStyle"
      />
    </div>

  </div>
</template>

<script>

import errorReporting from '@src/helpers/error-reporting';
import ContactLayoutDefault from './contact-layout-default.vue';
import ContactLayoutSingle from './contact-layout-single.vue';
import { MopsUserInteractionEvent } from '../../helpers/form-events';

export default {
  name: 'ContactFormRoot',
  props: {
    formId: { type: [String, Number], required: true },
    layout: { type: String },
    buttonStyle: { type: String, required: false },
  },
  components: {
    ContactLayoutDefault,
    ContactLayoutSingle,
  },
  data() {
    return {
      formEngaged: false,
      company: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      commentCapture: '',
      currentCustomer: '',
    };
  },
  emits: ['onSubmit'],
  methods: {
    onInputChange(target) {
      const key = target.name;
      const val = target.value;
      this.$data[key] = val;
      if (!this.$data.formEngaged && val !== '') {
        this.$data.formEngaged = true;
        window.dispatchEvent(MopsUserInteractionEvent('mops.form.engaged', { formId: this.$props.formId }));
      }
    },
    onSubmit(event, refs) {
      event.preventDefault();
      this.$emit('onSubmit', this.$data, refs);
    },
    onError(err) {
      errorReporting(err);
    },
  },
};
</script>
