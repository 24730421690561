<template>
  <div ref="CostCompareFormRoot">

    <div v-if="this.$props.layout == 'single'">
      <CostCompareLayoutSingle
        :onInputChange="onInputChange"
        :onSubmit="onSubmit"
        :onError="onError"
        :buttonStyle="buttonStyle"
      />
    </div>

    <div v-else>
      <CostCompareLayoutDefault
        :onInputChange="onInputChange"
        :onSubmit="onSubmit"
        :onError="onError"
        :buttonStyle="buttonStyle"
      />
    </div>

  </div>
</template>

<script>

import errorReporting from '@src/helpers/error-reporting';
import CostCompareLayoutDefault from './cost-compare-layout-default.vue';
import CostCompareLayoutSingle from './cost-compare-layout-single.vue';

export default {
  name: 'CostCompareFormRoot',
  props: {
    formId: { type: [String, Number], required: true },
    layout: { type: String },
    buttonStyle: { type: String, required: false },
  },
  components: {
    CostCompareLayoutDefault,
    CostCompareLayoutSingle,
  },
  data() {
    return {
      company: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      currentPaymentProcessor: '',
    };
  },
  emits: ['onSubmit', 'onError'],
  methods: {
    onInputChange(target) {
      const key = target.name;
      const val = target.value;
      this.$data[key] = val;
    },
    onSubmit(event, refs) {
      event.preventDefault();
      this.$emit('onSubmit', this.$data, refs);
    },
    onError(err) {
      errorReporting(err);
    },
  },
};
</script>
