<template>

  <div ref="MarOpsFormRoot">

    <div v-if="isDemoForm">
      <DemoFormRoot
        :formId="formId"
        :layout="layout"
        @onSubmit="handleSubmit"
        @onError="handleError"
        :buttonStyle="brandedButtonCss"
        :showPracticeSize="this.$props.showPracticeSize"
        :showComments="this.$props.showComments"
      />
    </div>

    <div v-else-if="isSignUpForm">
      <SignUpFormRoot
        :formId="formId"
        :layout="layout"
        @onSubmit="handleSubmit"
        @onError="handleError"
        :buttonStyle="brandedButtonCss"
        :showPracticeSize="this.$props.showPracticeSize"
        :showComments="this.$props.showComments"
      />
    </div>

    <div v-else-if="isContactForm">
      <ContactFormRoot
        :formId="formId"
        :layout="layout"
        @onSubmit="handleSubmit"
        @onError="handleError"
        :buttonStyle="brandedButtonCss"
      />
    </div>

    <div v-else-if="isCostCompareForm">
      <CostCompareFormRoot
        :formId="formId"
        :layout="layout"
        @onSubmit="handleSubmit"
        @onError="handleError"
        :buttonStyle="brandedButtonCss"
      />
    </div>

    <div v-else-if="isTradeshowForm">
      <TradeShowEventForm
        :formId="formId"
        :layout="layout"
        @onSubmit="handleSubmit"
        @onError="handleError"
        :buttonStyle="brandedButtonCss"
      />
    </div>

    <div v-else>
      <BasicFormRoot
        :formId="formId"
        :layout="layout"
        @onSubmit="handleSubmit"
        @onError="handleError"
        :buttonStyle="brandedButtonCss"
      />
    </div>
  </div>
</template>

<script>
import BasicFormRoot from '@src/marketo-forms/basic/index.vue';
import DemoFormRoot from '@src/marketo-forms/demo/index.vue';
import SignUpFormRoot from '@src/marketo-forms/signup/index.vue';
import ContactFormRoot from '@src/marketo-forms/contact/index.vue';
import CostCompareFormRoot from '@src/marketo-forms/cost-compare/index.vue';
import TradeShowEventForm from '@src/marketo-forms/tradeshow/index.vue';
import marOpsValidation from '@src/helpers/validation';
import {
  FORM_SIGNUP_ARRAY,
  FORM_DEMO_ARRAY,
  FORM_CONTACT_ARRAY,
  FORM_COST_COMPARE_ARRAY,
  FORM_TRADESHOW_EVENT,
} from '@src/marketo-forms/forms-manifest';

import errorReporting from '@src/helpers/error-reporting';
import Introspect from '@src/helpers/introspection';
import { MopsUserInteractionEvent } from '@src/helpers/form-events';

export default {
  name: 'MarOpsFormRoot',
  components: {
    DemoFormRoot,
    SignUpFormRoot,
    ContactFormRoot,
    BasicFormRoot,
    CostCompareFormRoot,
    TradeShowEventForm,
  },
  props: {
    formId: { type: [Number, String], required: true },
    layout: { type: String, default: 'default', required: false },
    redirectUrl: { type: String, default: 'https://www.affinipay.com/thank-you/contact/', required: false },
    settings: { type: Object, default: () => {}, required: false },
    conversion: { type: String, default: null, required: false },
    showPracticeSize: { type: Boolean, default: false, required: false },
    showComments: { type: Boolean, default: false, required: false },
  },
  computed: {
    isDemoForm() {
      const f = parseInt(this.$props.formId, 10);
      return FORM_DEMO_ARRAY.includes(f);
    },
    isSignUpForm() {
      const f = parseInt(this.$props.formId, 10);
      return FORM_SIGNUP_ARRAY.includes(f);
    },
    isContactForm() {
      const f = parseInt(this.$props.formId, 10);
      return FORM_CONTACT_ARRAY.includes(f);
    },
    isCostCompareForm() {
      const f = parseInt(this.$props.formId, 10);
      return FORM_COST_COMPARE_ARRAY.includes(f);
    },
    isTradeshowForm() {
      const f = parseInt(this.$props.formId, 10);
      return f === FORM_TRADESHOW_EVENT;
    },
    brandedButtonCss() {
      let brandName = '';
      const urlBrand = Introspect.brandFromUrl(window.location.href);
      if (urlBrand.assignedByUrl === false) {
        const formIdBrand = Introspect.brandFromFormId(this.$props.formId);
        brandName = formIdBrand.partner;
      } else {
        brandName = urlBrand.partner;
      }
      const btnCssClass = `btn_submit ${brandName}`;
      return btnCssClass;
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        window.dispatchEvent(MopsUserInteractionEvent('mops.form.loaded', { formId: this.$props.formId }));
      }, 1000);
    });
  },
  methods: {
    async handleSubmit(formData, refs) {
      try {
        const marOpsAllValid = marOpsValidation.validateAll(refs, formData);
        const mopsErrors = marOpsAllValid.filter((i) => i !== true);
        if (mopsErrors.length > 0) {
          window.dispatchEvent(MopsUserInteractionEvent('mops.form.invalid', { formId: this.$props.formId }));
          this.handleError('Failed Data Validation');
        } else {
          window.dispatchEvent(MopsUserInteractionEvent('mops.form.valid', { formId: this.$props.formId }));
          const payload = Object.assign(formData, { formId: this.$props.formId });
          const resp = await window.MarOps.sendFormPayload(payload);
          if (resp) {
            window.dispatchEvent(MopsUserInteractionEvent('mops.form.success', {
              formId: this.$props.formId, form_request_id: resp.form_request_id,
            }));
          }

          const passRef = resp.mops_xid || '';
          const receiptData = (this.isDemoForm ? encodeURI(`?date=${formData.demoDate}&time=${formData.demoTime}&mops_xid=${passRef}`) : `?mops_xid=${passRef}`);
          let redirectTo = this.$props.redirectUrl;

          if (this.$props.conversion) {
            window.MarOps.trackConversion(this.$props.conversion);
          }

          if (this.isSignUpForm) {
            redirectTo = `https://${window.location.host.replace('lp.', 'www.')}/thank-you/sign-up/`;
            if (payload.call_for_walkthrough === 'Yes' || payload.call_for_walk_through === 'Yes') {
              redirectTo = `${redirectTo}walkthrough/`;
              window.MarOps.trackConversion('form_submit_signup_walk_through');
            }
          }

          if (!this.isTradeshowForm) {
            redirectTo = `${redirectTo}${receiptData}`;
            window.location = redirectTo;
          }
        }
      } catch (err) {
        window.dispatchEvent(MopsUserInteractionEvent('mops.form.error', { formId: this.$props.formId, error: err.message }));
        this.handleError(err);
      }
    },
    handleError(err) {
      return errorReporting(err);
    },
  },
};
</script>
