/**
 * Error Messages displayed in form component
 */

export const ERROR_REQUIRED_EMPTY = 'This field is required';
export const ERROR_INVALID_ALPHA = 'Must contain only letters';
export const ERROR_INVALID_NUMERIC = 'Must contain only numbers';
export const ERROR_INVALID_PHONE = 'Must be a valid phone number';
export const ERROR_INVALID_EMAIL = 'Must be a valid email address';
export const ERROR_INVALID_PERSON = 'May only contain letters and spaces';
export const ERROR_INVALID_COMPANY = 'Valid characters include any alphanumeric, space, hyphen and ampersand.';
export const ERROR_INVALID_MIN = (n) => `Must have at least ${n} characters`;
export const ERROR_INVALID_MAX = (n) => `Must be less than ${n} characters`;
