<template>

<div :class="inputGroupCss">
  <div class="label_wrap">
    <label :for="name">{{ label }}</label>
  </div>
  <div class="input_wrap">
    <VueSelect
      :inputId="name"
      :ref="name"
      :label="label"
      :name="name"
      :options="options"
      :appendToBody="true"
      @option:selected="inputChanged"
      @blur="validInput"
      :v-model="name"
    />
  </div>
</div>

</template>

<script>

import VueSelect from 'vue-select';

export default {
  name: 'InputSelect',
  props: {
    name: { type: String, required: true },
    options: { type: Object, required: true },
    label: { type: String, default: '' },
    rules: { type: String, default: '' },
    initialValue: { type: String },
  },
  components: { VueSelect },
  computed: {
    inputGroupCss() {
      return (this.selectedValue === '' ? 'input_group form-input-error' : 'input_group');
    },
  },
  data() {
    return {
      selected: null,
      selectedValue: '',
      errorMessage: '',
    };
  },
  emits: ['inputChanged'],
  methods: {
    inputChanged(val) {
      this.$data.selectedValue = val;
      this.$data.selected = val;
      this.$emit('inputChanged', {
        name: this.$props.name,
        value: val,
      });
    },
    validInput(event) {
      event.preventDefault();
      const val = event.target.value;
      this.errorMessage = (val === '' ? 'This field is required' : '');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$data.selected = this.$props.initialValue;
      this.$data.selectedValue = this.$props.initialValue;
      if (this.$data.selected !== '') {
        this.inputChanged(this.$props.initialValue);
      }
    });
  },
  watch: {
    initialValue(val) {
      this.$emit('inputChanged', {
        name: this.$props.name,
        value: val,
      });
    },
  },
};
</script>

<style>
  @import 'vue-select/dist/vue-select.css';
</style>
