<template>
  <div :ref="name" :class="inputGroupCss">
    <div class="label_wrap">
      <label :for="name">{{ label }}</label>
    </div>
    <div class="input_wrap">
      <input
        :id="name"
        :name="name"
        :type="type"
        :label="label"
        :data-rules="rules"
        v-model="value"
        @input="handleChange"
        @blur="validInput"
        :autocomplete="autoCompleteOptions"
      />
    </div>
    <span class="error_message_text">{{ errorMessage }}</span>
  </div>
</template>

<script>

import { ref } from 'vue';
import marOpsValidator from '@src/helpers/validation';

export default {
  name: 'InputGroup',
  props: {
    name: { type: String, required: true },
    label: { type: String, default: '' },
    type: { type: String, default: 'text' },
    rules: { type: String, default: '' },
    error: { type: String, default: '' },
    initialValue: { type: String, default: '' },
  },
  computed: {
    inputGroupCss() {
      return (this.$data.errorMessage !== '' ? 'input_group form-input-error' : 'input_group');
    },
    autoCompleteOptions() {
      let autocomplete = 'off';
      if (this.$props.name === 'company') autocomplete = 'organization';
      if (this.$props.name === 'firstName') autocomplete = 'given-name';
      if (this.$props.name === 'lastName') autocomplete = 'family-name';
      if (this.$props.name === 'email') autocomplete = 'email';
      if (this.$props.name === 'phone') autocomplete = 'tel';
      return autocomplete;
    },
  },
  data() {
    return {
      value: '',
      errorMessage: '',
    };
  },
  emits: ['inputChanged'],
  methods: {
    handleChange(event) {
      this.value = event.target.value;
      this.$emit('inputChanged', event.target);
    },
    validInput(event) {
      const val = event.target.value || '';
      const verifications = this.$props.rules.split('|');
      let msg = true;
      verifications.forEach((validationType) => {
        if (validationType === 'required') {
          msg = marOpsValidator.validateNotEmpty(val);
        }
        if (validationType === 'email') {
          msg = marOpsValidator.validateEmail(val);
        }
        if (validationType === 'phone') {
          msg = marOpsValidator.validatePhone(val);
        }
        if (validationType === 'company') {
          msg = marOpsValidator.validateCompanyName(val);
        }
        if (validationType === 'firstName' || validationType === 'lastName') {
          msg = marOpsValidator.validatePersonName(val);
        }

        this.errorMessage = (msg === true ? '' : msg);
      });
    },
  },
  mounted() {
    this.ref = ref(this.$props);
    this.$data.value = this.$props.initialValue;
  },
};
</script>
