<template>
  <div ref="DemoLayoutSingle" id="wrapperDemoForm" class="single_layout m-4">

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="firstName"
        name="firstName"
        label="First Name"
        rules="required"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="lastName"
        name="lastName"
        label="Last Name"
        rules="required"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="company"
        name="company"
        label="Company Name"
        rules="required"
        @inputChanged="onInputChange"
      />
    </div>

    <div v-if="showPracticeSize" class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputSelect
        ref="practiceSize"
        name="practiceSize"
        label="Practice Size"
        rules="required"
        :options="practiceSizeOptions"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="email"
        name="email"
        label="Email"
        rules="required|email"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputPhone
        ref="phone"
        name="phone"
        label="Phone"
        rules="required|phone"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputDatePicker
        ref="demoDate"
        name="demoDate"
        label="Demo Request Date"
        rules="required"
        @dateSelected="handleDateSelected"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputTimePicker
        ref="demoTime"
        name="demoTime"
        label="Select a Time"
        rules="required"
        @timeSelected="onTimeChange"
        :selectedDate="selectedDate"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <button
        :class="buttonStyle"
        @click="handleSubmitClick"
      >
        Submit Form
      </button>
    </div>

  </div>
</template>

<script>

import InputGroup from '@src/components/input-group.vue';
import InputPhone from '@src/components/input-phone.vue';
import InputSelect from '@src/components/input-select.vue';
import InputDatePicker from '@src/components/input-datepicker.vue';
import InputTimePicker from '@src/components/input-time-picker.vue';

export default {
  name: 'DemoLayoutSingle',
  props: {
    showPracticeSize: { type: Boolean, required: false, default: false },
    onInputChange: { type: Function, required: true },
    onDateChange: { type: Function, required: true },
    onTimeChange: { type: Function, required: true },
    onSubmit: { type: Function, required: true },
    onError: { type: Function, required: true },
    buttonStyle: { type: String, required: false, default: 'btn_submit clientpay' },
  },
  components: {
    InputGroup,
    InputDatePicker,
    InputTimePicker,
    InputPhone,
    InputSelect,
  },
  computed: {
    practiceSizeOptions() {
      return [
        '1-2',
        '3-10',
        '11-20',
        '21-50',
        '51-100',
        '100-500',
        '501+',
      ];
    },
  },
  data() {
    return {
      selectedDate: new Date(),
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.handleDateSelected(new Date());
    });
  },
  methods: {
    handleDateSelected(d) {
      this.$data.selectedDate = d;
      const dateEmitAction = {
        name: 'demoDate',
        value: d,
      };
      this.$props.onInputChange(dateEmitAction);
      this.$props.onDateChange(dateEmitAction);
    },
    handleSubmitClick(event) {
      this.$props.onSubmit(event, this.$refs);
    },
  },
};
</script>
