<template>
  <div class="checkbox_wrap">
    <div :class="errorCss"><label>{{  label  }}</label></div>
    <div class="input_checkbox_wrap" v-for="opt in this.$data.available" :key="opt">
      <div class="checkbox_option_row">
        <input type="checkbox" :id="opt" :name="opt" :onchange="handleChange" :value="opt" />
        <label :for="opt" class="label_wrap_checkbox">{{ opt }}</label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InputCheckboxGroup',
  props: {
    name: { type: String, required: true },
    label: { type: String, required: true },
    options: { type: Array, required: true },
  },
  computed: {
    errorCss() {
      return this.$data.checked.length === 0 ? 'form-input-error' : '';
    },
  },
  data() {
    return {
      available: this.$props.options,
      checked: [],
    };
  },
  emits: [
    'inputChanged',
  ],
  mounted() {
    this.$nextTick(() => {
      // console.debug('InputCheckboxGroup Mounted');
    });
  },
  methods: {
    handleChange(event) {
      const val = event.target.value;
      if (event.target.checked) {
        this.$data.checked.push(val);
      } else {
        this.$data.checked = this.$data.checked.filter((item) => item !== val);
      }
      this.$emit('inputChanged', this.$data.checked);
      return event;
    },
  },
};
</script>
