<template>
  <div ref="maropsRoot" class="maropsRoot"></div>
</template>

<script>

import { h } from 'vue';
import MarOpsFormRoot from '@src/components/form-root.vue';

const AppWrapper = (props) => h(MarOpsFormRoot, props);
export default AppWrapper;

</script>

<style>
  @import '@src/assets/index.css';
</style>
