<template>
  <div ref="BasicFormRoot" class="single_layout m-4">

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="firstName"
        name="firstName"
        label="First Name"
        rules="required"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="lastName"
        name="lastName"
        label="Last Name"
        rules="required"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="company"
        name="company"
        label="Company Name"
        rules="required"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="email"
        name="email"
        label="Email Address"
        rules="required|email"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputPhone
        ref="phone"
        name="phone"
        label="Phone Number"
        rules="required|phone"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <button
        :class="buttonStyle"
        @click="handleSubmitClick"
      >
        Submit Form
      </button>
    </div>
  </div>
</template>

<script>

import InputGroup from '@src/components/input-group.vue';
import InputPhone from '@src/components/input-phone.vue';
import errorReporting from '@src/helpers/error-reporting';
import { MopsUserInteractionEvent } from '@src/helpers/form-events';

export default {
  name: 'BasicFormRoot',
  props: {
    formId: { type: [String, Number], required: true },
    layout: { type: String },
    buttonStyle: { type: String, required: false },
  },
  components: {
    InputGroup,
    InputPhone,
  },
  data() {
    return {
      formEngaged: false,
      company: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    };
  },
  emits: ['onSubmit'],
  methods: {
    onInputChange(target) {
      const key = target.name;
      const val = target.value;
      this.$data[key] = val;
      if (!this.$data.formEngaged && val !== '') {
        this.$data.formEngaged = true;
        window.dispatchEvent(MopsUserInteractionEvent('mops.form.engaged', { formId: this.$props.formId }));
      }
    },
    handleSubmitClick(event) {
      event.preventDefault();
      this.$emit('onSubmit', this.$data, this.$refs);
    },
    onError(err) {
      errorReporting(err);
    },
  },
};
</script>
