<template>
  <div ref="SignUpFormRoot">

    <div v-if="layout == 'single'">
      <SignUpLayoutSingle
        :onInputChange="onInputChange"
        :onSubmit="onSubmit"
        :onError="onError"
        :buttonStyle="buttonStyle"
      />
    </div>

    <div v-else>
      <SignUpLayoutDefault
        :onInputChange="onInputChange"
        :onSubmit="onSubmit"
        :onError="onError"
        :buttonStyle="buttonStyle"
        :showPracticeSize="showPracticeSize"
        :showComments="showComments"
      />
    </div>

  </div>
</template>

<script>

import errorReporting from '@src/helpers/error-reporting';
import SignUpLayoutDefault from './signup-layout-default.vue';
import SignUpLayoutSingle from './signup-layout-single.vue';
import { MopsUserInteractionEvent } from '../../helpers/form-events';

export default {
  name: 'SignUpFormRoot',
  props: {
    formId: { type: [String, Number], required: true },
    layout: { type: String, required: false, default: 'default' },
    buttonStyle: { type: String, required: false },
    showPracticeSize: { type: Boolean, required: false, default: false },
    showComments: { type: Boolean, required: false, default: false },
  },
  components: {
    SignUpLayoutDefault,
    SignUpLayoutSingle,
  },
  data() {
    return {
      formEngaged: false,
      company: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      call_for_walkthrough: '',
    };
  },
  emits: ['onSubmit'],
  methods: {
    onInputChange(target) {
      const key = target.name || '';
      const val = target.value || '';
      if (key !== '' && val !== '') {
        this.$data[key] = val;
      }
      if (key === 'call_for_walkthorugh' || key === 'requestContact') {
        this.$data.call_for_walkthrough = val;
      }
      if (val !== '' && !this.formEngaged) {
        this.$data.formEngaged = true;
        window.dispatchEvent(MopsUserInteractionEvent('mops.form.engaged', { formId: this.$props.formId }));
      }
    },
    onSubmit(event, refs) {
      event.preventDefault();
      this.$emit('onSubmit', this.$data, refs);
    },
    onError(err) {
      errorReporting(err);
    },
  },
};
</script>
