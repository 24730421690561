import { add } from 'date-fns';

export function isToday(date) {
  const today = new Date();
  const srcDate = date instanceof Date ? date : today;
  return srcDate.toLocaleDateString() === today.toLocaleDateString();
}

export function roundToNextHalfHour(date) {
  const nextOffset = (date.getMinutes() < 31 ? 30 : 0);
  date.setMinutes(nextOffset);
  return (nextOffset === 0 ? add(date, { hours: 1 }) : date);
}

export function formatTimeOption(date) {
  const srcDate = date instanceof Date ? date : new Date();

  const userTimeZone = srcDate.toLocaleDateString('en-US', {
    day: '2-digit',
    timeZoneName: 'short',
  }).slice(4);

  const timeSlot = new Intl.DateTimeFormat('en-US', {
    timeStyle: 'short',
  }).format(srcDate);
  return `${timeSlot} ${userTimeZone}`;
}

export function defaultTimeSlots(activeDate, startHour = 7, endHour = 18.5, slotSize = 0.5) {
  const slots = [];
  const srcDate = activeDate instanceof Date ? activeDate : new Date();

  for (let time = startHour; time <= endHour; time += slotSize) {
    const hour = Math.floor(time);
    const minute = (time - hour) * 60;
    const slotDate = new Date();
    slotDate.setMonth(srcDate.getMonth());
    slotDate.setDate(srcDate.getDate());
    slotDate.setFullYear(srcDate.getFullYear());
    slotDate.setHours(hour);
    slotDate.setMinutes(minute);
    slots.push(slotDate);
  }
  return slots;
}

export function timeSlotsForDate(date) {
  const current = new Date();
  const allTimeSlots = defaultTimeSlots(date);
  let timeslots = allTimeSlots;
  const dateWithLeadTime = add(date, { minutes: 30 });
  const roundedCstDate = roundToNextHalfHour(dateWithLeadTime);
  const isWeekend = [0, 6].includes(roundedCstDate.getDay());
  const isFutureDate = date > current;
  if (date instanceof Date && isToday(date) && !isWeekend) {
    timeslots = allTimeSlots.filter((timeSlot) => timeSlot >= roundedCstDate);
  } else {
    timeslots = !isWeekend && isFutureDate ? allTimeSlots : [];
  }
  return timeslots;
}

const DatePickerHelpers = {
  isToday,
  roundToNextHalfHour,
  formatTimeOption,
  defaultTimeSlots,
  timeSlotsForDate,
};

export default DatePickerHelpers;
