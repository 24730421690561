<template>

<div :class="inputGroupCss">
  <div class="label_wrap">
    <label :for="name">{{ label }}</label>
  </div>
  <div class="input_wrap">
    <VueSelect
      ref="timepicker"
      :inputId="name"
      :label="label"
      :name="name"
      :options="availableTimeSlots"
      :getOptionLabel="timeslot => timeslot.value"
      :appendToBody="true"
      @option:selected="timeSelected"
      @blur="validInput"
      v-model="timePickerValue"
    />
  </div>
</div>

</template>

<script>

import VueSelect from 'vue-select';
import {
  timeSlotsForDate,
  formatTimeOption,
} from '../helpers/date-picker-helpers';

export default {
  name: 'InputTimePicker',
  props: {
    label: { type: String, default: '' },
    name: { type: String, default: '' },
    rules: { type: String, default: '' },
    selectedDate: { type: Date, default: '' },
  },
  components: { VueSelect },
  computed: {
    availableTimeSlots() {
      const activeDate = this.$props.selectedDate;
      const allSlots = timeSlotsForDate(activeDate);
      const slots = [];
      allSlots.forEach((time) => {
        slots.push({
          label: formatTimeOption(time),
          value: formatTimeOption(time),
        });
      });
      return slots;
    },
    inputGroupCss() {
      return (this.$data.errorMessage !== '' ? 'input_group form-input-error' : 'input_group');
    },
    autoCompleteOptions() {
      return 'autocomplete="organization"';
    },
  },
  data() {
    return {
      selected: null,
      timePickerValue: '',
      openTimeSlots: [],
      errorMessage: '',
    };
  },
  emits: ['timeSelected'],
  methods: {
    timeSelected(t) {
      // const timeVal = typeof(t) === 'undefined' ? 'No Preference' : t.value;
      this.timePickerValue = t;
      this.selected = t;

      this.$emit('timeSelected', {
        name: this.$props.name,
        value: t,
      });
    },
    validInput(event) {
      event.preventDefault();
      const val = event.target.value;
      this.errorMessage = (val === '' ? 'This field is required' : '');
    },
  },
  mounted() {
    this.$nextTick(() => {
      const d = new Date();
      const ts = timeSlotsForDate(d);
      this.openTimeSlots = ts;
      this.timeSelected(ts[0]);
    });
  },
  watch: {
    selectedDate(d) {
      const ts = timeSlotsForDate(d);
      if (ts.length > 0) {
        this.openTimeSlots = ts;
        this.timeSelected(ts[0]);
      }
    },
  },
};
</script>

<style>
  @import 'vue-select/dist/vue-select.css';
</style>
