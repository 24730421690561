<template>
  <div :ref="name" :class="inputGroupCss">
    <div class="label_wrap">
      <label :for="name">{{ label }}</label>
    </div>
    <div class="input_wrap">
      <textarea
        :id="name"
        :name="name"
        :label="label"
        :rules="rules"
        v-model="value"
        @change="handleChange"
        @blur="validInput"
      >
      </textarea>
      <span class="error_message_text">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>

import { ref } from 'vue';
import marOpsValidator from '../helpers/validation';

export default {
  name: 'InputTextArea',
  props: {
    name: { type: String, required: true },
    label: { type: String, default: '' },
    type: { type: String, default: 'text' },
    rules: { type: String, default: '' },
  },
  computed: {
    inputGroupCss() {
      return (this.$data.errorMessage !== '' ? 'input_group form-input-error' : 'input_group');
    },
  },
  data() {
    return {
      value: '',
      errorMessage: '',
    };
  },
  emits: ['inputChanged'],
  methods: {
    handleChange(event) {
      this.value = event.target.value;
      this.$emit('inputChanged', event.target);
    },
    validInput(event) {
      event.preventDefault();
      const val = event.target.value;
      const verifications = this.$props.rules.split('|');
      let msg = true;
      verifications.forEach((validationType) => {
        if (validationType === 'required') {
          msg = marOpsValidator.validateNotEmpty(val);
        }
        this.errorMessage = (msg === true ? '' : msg);
      });
    },
  },
  mounted() {
    this.ref = ref(this.$props);
  },
};
</script>
