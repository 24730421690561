<template>

  <div ref="DemoLayoutDefault"  id="wrapperDemoForm" class="m-4">

    <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="firstName"
        name="firstName"
        label="First Name:"
        rules="required|firstName"
        @inputChanged="onInputChange"
      />
      <InputGroup
        ref="lastName"
        name="lastName"
        label="Last Name:"
        rules="required|lastName"
        @inputChanged="onInputChange"
      />
    </div>

    <div v-if="showPracticeSize" class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="company"
        name="company"
        label="Firm Name:"
        rules="required|company"
        @inputChanged="onInputChange"
      />
      <InputGroup
        ref="practiceSize"
        name="practiceSize"
        label="Number of Employees:"
        rules="required"
        type="number"
        initialValue="1"
        @inputChanged="onInputChange"
      />
    </div>

    <div v-else class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="company"
        name="company"
        label="Firm Name:"
        rules="required|company"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="email"
        name="email"
        label="Email Address:"
        rules="required|email"
        @inputChanged="onInputChange"
      />
      <InputPhone
        ref="phone"
        name="phone"
        label="Phone Number:"
        rules="required|phone"
        @inputChanged="onInputChange"
      />
    </div>

    <div v-if="this.$props.showComments" class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputTextArea
        ref="comments"
        name="comments"
        label="What would you like to discuss or solve?"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2">
      <InputDatePicker
        ref="demoDate"
        name="demoDate"
        label="Demo Request Date"
        rules="required"
        @dateSelected="handleDateSelected"
      />
      <InputTimePicker
        ref="demoTime"
        name="demoTime"
        label="Preferred demo time"
        rules="required"
        @timeSelected="onTimeChange"
        :selectedDate="selectedDate"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <button
        ref="btnSubmit"
        :class="buttonStyle"
        @click="handleSubmitClick"
      >
      Schedule Demo
      </button>
    </div>

  </div>
</template>

<script>
import InputGroup from '../../components/input-group.vue';
import InputPhone from '../../components/input-phone.vue';
import InputDatePicker from '../../components/input-datepicker.vue';
import InputTimePicker from '../../components/input-time-picker.vue';
import InputTextArea from '../../components/input-textarea.vue';

export default {
  name: 'DemoLayoutDefault',
  props: {
    onInputChange: { type: Function, required: true },
    onDateChange: { type: Function, required: true },
    onTimeChange: { type: Function, required: true },
    onSubmit: { type: Function, required: true },
    onError: { type: Function, required: true },
    buttonStyle: { type: String, required: false, default: 'btn_submit affinipay' },
    showPracticeSize: { type: Boolean, required: false, default: false },
    showComments: { type: Boolean, required: false, default: false },
  },
  components: {
    InputGroup,
    InputDatePicker,
    InputTimePicker,
    InputPhone,
    InputTextArea,
  },
  computed: {
    practiceSizeOptions() {
      return [
        '1-2',
        '3-10',
        '11-20',
        '21-50',
        '51-100',
        '100-500',
        '501+',
      ];
    },
  },
  data() {
    return {
      selectedDate: new Date(),
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.handleDateSelected(new Date(), false);
    });
  },
  methods: {
    handleDateSelected(d) {
      this.$data.selectedDate = d;
      const datePayload = {
        name: 'demoDate',
        value: d,
      };
      this.$props.onInputChange(datePayload);
      this.$props.onDateChange(datePayload);
    },
    handleSubmitClick(event) {
      event.preventDefault();
      this.$props.onSubmit(event, this.$refs);
    },
  },
};
</script>
