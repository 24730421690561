<template>
  <div ref="DemoFormRoot">

    <div v-if="this.$props.layout == 'single'">
      <DemoLayoutSingle
        :onInputChange="onInputChange"
        :onDateChange="onDateChange"
        :onTimeChange="onTimeChange"
        :onSubmit="onSubmit"
        :onError="onError"
        :buttonStyle="buttonStyle"
      />
    </div>

    <div v-else>
      <DemoLayoutDefault
        :onInputChange="onInputChange"
        :onDateChange="onDateChange"
        :onTimeChange="onTimeChange"
        :onSubmit="onSubmit"
        :onError="onError"
        :buttonStyle="buttonStyle"
        :showPracticeSize="this.$props.showPracticeSize"
        :showComments="this.$props.showComments"
      />
    </div>

  </div>
</template>

<script>

import errorReporting from '@src/helpers/error-reporting';
import DemoLayoutDefault from './demo-layout-default.vue';
import DemoLayoutSingle from './demo-layout-single.vue';
import { MopsUserInteractionEvent } from '../../helpers/form-events';

export default {
  name: 'DemoFormRoot',
  props: {
    formId: { type: [String, Number], required: true },
    layout: { type: String },
    buttonStyle: { type: String, required: false },
    showComments: { type: Boolean, required: false, default: false },
    showPracticeSize: { type: Boolean, required: false, default: true },
  },
  components: {
    DemoLayoutSingle,
    DemoLayoutDefault,
  },
  data() {
    return {
      formEngaged: false,
      company: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      demoDate: '',
      demoTime: '',
      practiceSize: 0,
      comments: '',
    };
  },
  emits: ['onSubmit', 'onError'],
  methods: {
    onInputChange(target) {
      const key = target.name;
      const val = target.value;
      if (key !== '' && typeof val !== 'undefined') {
        this.$data[key] = val;
        if (!this.$data.formEngaged && val !== '' && key !== 'demoDate') {
          this.$data.formEngaged = true;
          window.dispatchEvent(MopsUserInteractionEvent('mops.form.engaged', { formId: this.$props.formId }));
        }
      }
    },
    onDateChange(data) {
      this.$data.demoDate = data.value.toLocaleDateString();
    },
    onTimeChange(args) {
      let demoTime = args.value;

      if (typeof (args.value) === 'undefined') {
        demoTime = 'No Preference';
      }
      if (typeof (args.value) === 'string') {
        demoTime = args.value;
      }
      if (typeof (args.value) === 'object') {
        demoTime = args.value.value;
      }
      this.$data.demoTime = demoTime;
    },
    onSubmit(event, refs) {
      event.preventDefault();
      this.$emit('onSubmit', this.$data, refs);
    },
    onError(err) {
      errorReporting(err);
    },
  },
};
</script>
