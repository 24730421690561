import { createApp } from 'vue';
import { createPinia } from 'pinia';
import vSelect from 'vue-select';
import Maska from 'maska';
import AppWrapper from '../src/App.vue';

const mopsScript = document.createElement('script');
mopsScript.setAttribute('src', 'https://info.affinipay.com/js/0.0.12/marops.umd.js');
mopsScript.setAttribute('type', 'application/javascript');
document.head.appendChild(mopsScript);
mopsScript.addEventListener('load', () => {
  const mopsLoader = () => {
    if (!window.MarOps) {
      setTimeout(() => {
        mopsLoader();
      }, 200);
    } else {
      window.MarOps.pageView();
      window.addEventListener('mops.form.loaded', (event) => {
        window.MarOps.logEvent(event.detail);
      });
      window.addEventListener('mops.form.engaged', (event) => {
        window.MarOps.logEvent(event.detail);
      });
      window.addEventListener('mops.form.valid', (event) => {
        window.MarOps.logEvent(event.detail);
      });
      window.addEventListener('mops.form.invalid', (event) => {
        window.MarOps.logEvent(event.detail);
      });
      window.addEventListener('mops.form.submit', (event) => {
        window.MarOps.logEvent(event.detail);
      });
      window.addEventListener('mops.form.success', (event) => {
        window.MarOps.logEvent(event.detail);
      });
      window.addEventListener('mops.form.error', (event) => {
        window.MarOps.logEvent(event.detail);
      });
    }
  };
  mopsLoader();
});

const MarOpsForm = {
  load: (formConfig) => {
    const mountSelector = formConfig.domSelector || '#marops'
    const libWrapper = AppWrapper(formConfig);
    const formApp = createApp(libWrapper);
    formApp.use(createPinia());
    formApp.use(Maska);
    formApp.component('vSelect', vSelect);
    formApp.mount(mountSelector);
  },
};

export default MarOpsForm;
