<template>
  <div class="checkbox_wrap" :ref="name">
    <div class="input_wrap_checkbox">
      <input
        type="checkbox"
        :id="name"
        :name="name"
        @click="handleClick"
        :value="modelValue"
        :checked="checkedValue"
      />
      <label :for="name">{{ label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputCheckbox',
  props: {
    name: { type: String, required: true },
    label: { type: String, default: '' },
    isChecked: { type: Boolean, required: false, default: false },
  },
  computed: {
    checkedValue() {
      return this.$props.isChecked ? 'checked="checked"' : null;
    },
  },
  data() {
    return {
      modelValue: 'No',
    };
  },
  methods: {
    handleClick(event) {
      const newState = event.target.checked ? 'Yes' : 'No';
      this.$data.modelValue = newState;
      this.$emit('inputChanged', { name: event.target.name, value: newState });
    },
  },
};
</script>
