import {
  REGEX_EMAIL_ADDRESS,
  REGEX_PHONE_NUMBER,
  REGEX_NUMERIC_ONLY,
  REGEX_ALPHA_ONLY,
  REGEX_COMPANY_NAME,
  REGEX_PERSON_NAME,
} from '../constants';

import {
  ERROR_REQUIRED_EMPTY,
  ERROR_INVALID_ALPHA,
  ERROR_INVALID_NUMERIC,
  ERROR_INVALID_MIN,
  ERROR_INVALID_MAX,
  ERROR_INVALID_PERSON,
  ERROR_INVALID_COMPANY,
  ERROR_INVALID_EMAIL,
  ERROR_INVALID_PHONE,
} from '../constants/error-messages';

const marOpsValidator = {
  validateNotEmpty(inputStr) {
    return (inputStr !== undefined && inputStr !== '' ? true : ERROR_REQUIRED_EMPTY);
  },
  validateAlpha(inputStr) {
    return (REGEX_ALPHA_ONLY.test(inputStr.toString()) ? true : ERROR_INVALID_ALPHA);
  },
  validateNumeric(inputStr) {
    return (REGEX_NUMERIC_ONLY.test(inputStr.toString()) ? true : ERROR_INVALID_NUMERIC);
  },
  validatePhone(inputStr) {
    const cleanPhone = inputStr.toString().replace(/\D/g, '');
    let msg = true;
    if (!REGEX_PHONE_NUMBER.test(cleanPhone)) {
      msg = ERROR_INVALID_PHONE;
      if (inputStr.length === 0) msg = ERROR_REQUIRED_EMPTY;
    }
    return msg;
  },
  validateEmail(inputStr) {
    let msg = true;
    if (!REGEX_EMAIL_ADDRESS.test(inputStr.toString())) {
      msg = ERROR_INVALID_EMAIL;
      if (inputStr.length === 0) msg = ERROR_REQUIRED_EMPTY;
    }
    return msg;
  },
  validateMinLength(inputStr, minLength) {
    return (inputStr.length >= minLength ? true : ERROR_INVALID_MIN(minLength));
  },
  validateMaxLength(inputStr, maxLength) {
    return (inputStr.length <= maxLength ? true : ERROR_INVALID_MAX(maxLength));
  },
  validatePersonName(inputStr) {
    let msg = '';
    if (!REGEX_PERSON_NAME.test(inputStr.toString())) {
      msg = ERROR_INVALID_PERSON;
      if (inputStr.length < 2) msg = ERROR_INVALID_MIN(2);
      if (inputStr.length > 20) msg = ERROR_INVALID_MAX(20);
      if (inputStr.length === 0) msg = ERROR_REQUIRED_EMPTY;
    } else {
      msg = true;
    }
    return msg;
  },
  validateCompanyName(inputStr) {
    let msg = '';
    if (!REGEX_COMPANY_NAME.test(inputStr.toString())) {
      msg = ERROR_INVALID_COMPANY;
      if (inputStr.length < 3) msg = ERROR_INVALID_MIN(3);
      if (inputStr.length > 40) msg = ERROR_INVALID_MAX(40);
      if (inputStr.length === 0) msg = ERROR_REQUIRED_EMPTY;
    } else {
      msg = true;
    }
    return msg;
  },
  validateAll(formRefs, userInput) {
    const validationTests = Object.entries(formRefs).map((vueRef) => {
      const k = vueRef[0] || null;
      const itemValue = userInput[k];
      const rawRules = ((formRefs[k] !== undefined && formRefs[k] !== null && formRefs[k].rules !== undefined) ? formRefs[k].rules : '');
      const rules = rawRules.toString().split('|');
      let isValid = true;
      if (rules.length > 0) {
        rules.forEach((item) => {
          switch (item) {
            case 'required':
              isValid = marOpsValidator.validateNotEmpty(itemValue);
              break;

            case 'firstName':
              isValid = marOpsValidator.validatePersonName(itemValue);
              break;

            case 'lastName':
              isValid = marOpsValidator.validatePersonName(itemValue);
              break;

            case 'company':
              isValid = marOpsValidator.validateCompanyName(itemValue);
              break;

            case 'email':
              isValid = marOpsValidator.validateEmail(itemValue);
              break;

            case 'phone':
              isValid = marOpsValidator.validatePhone(itemValue);
              break;

            case 'numeric':
              isValid = marOpsValidator.validateNumeric(itemValue);
              break;

            case 'alpha':
              isValid = marOpsValidator.validateAlpha(itemValue);
              break;

            default:
              isValid = true;
              break;
          }

          const elWrapper = formRefs[k];
          elWrapper.errorMessage = (isValid !== true) ? isValid : '';
          return isValid;
        });
      }
      return isValid;
    });
    return validationTests;
  },
};

export default marOpsValidator;
