/** Demo Forms Per Brand * */
export const FORM_DEMO_LAWPAY = 1012; // 2198;
export const FORM_DEMO_LAWPAY_PRO = 1213;
export const FORM_DEMO_CPACHARGE = 1022; // 1644;
export const FORM_DEMO_CLIENTPAY = 1023; // 2791;
export const FORM_DEMO_MYCASE = 1034;
export const FORM_DEMO_CASEPEER = 1024;
export const FORM_DEMO_DOCKETWISE = 1037;
export const FORM_DEMO_LAWPAY_ENTERPRISE = 1046; // 3226;

/** Trial Forms */
export const FORM_TRIAL_LAWPAY_PRO = 1394;
export const FORM_TRIAL_MYCASE = 1035;

/** SignUp Forms */
export const FORM_SIGNUP_AFFINIPAY = 1045; // 2070;
export const FORM_SIGNUP_ASSOCIATIONS = 1045; // 2070;
export const FORM_SIGNUP_CLIENTPAY = 1021; // 2736;
export const FORM_SIGNUP_CPACHARGE = 1020; // 1646;
export const FORM_SIGNUP_LAWPAY = 1001; // 1541
export const FORM_SIGNUP_MYCASE = 3227;

/* Contact Forms */
export const FORM_CONTACT_AFFINIPAY = 1017; // 1875;
export const FORM_CONTACT_CLIENTPAY = 1016; // 2688;
export const FORM_CONTACT_CPACHARGE = 1015; // 1642;
export const FORM_CONTACT_LAWPAY = 1013; // 1143;
export const FORM_CONTACT_LAWPAY_ENTERPRISE = 1047; // 2082;
export const FORM_CONTACT_MYCASE = 1031;

/* Tradeshow Event Forms */
export const FORM_TRADESHOW_EVENT = 1338;
/* Referral Forms */
export const FORM_REFERRAL_CLIENTPAY = 1374; // 2787;
export const FORM_REFERRAL_CPACHARGE = 1373; // 1702;
export const FORM_REFERRAL_LAWPAY = 1029; // 1352;

/* Partner Contact Forms */
export const FORM_CONTACT_CLIENTPAY_PARTNER = 1053; // 2794;
export const FORM_CONTACT_LAWPAY_PARTNER = 1053; // 2025;

export const FROM_COST_COMPARE_LAWPAY = 3247;

export const FORM_CONTACT_ARRAY = [ //  %w( 1994 2027        2794 2770 2771)
  FORM_CONTACT_AFFINIPAY,
  FORM_CONTACT_CLIENTPAY,
  FORM_CONTACT_CPACHARGE,
  FORM_CONTACT_LAWPAY,
  FORM_CONTACT_LAWPAY_ENTERPRISE,
  FORM_CONTACT_CLIENTPAY_PARTNER,
  FORM_CONTACT_LAWPAY_PARTNER,
];

export const FORM_TRIAL_ARRAY = [
  FORM_TRIAL_LAWPAY_PRO,
  FORM_TRIAL_MYCASE,
];

export const FORM_DEMO_ARRAY = [
  FORM_DEMO_CLIENTPAY,
  FORM_DEMO_CPACHARGE,
  FORM_DEMO_LAWPAY,
  FORM_DEMO_LAWPAY_ENTERPRISE,
  FORM_DEMO_LAWPAY_PRO,
  FORM_DEMO_CASEPEER,
  FORM_DEMO_MYCASE,
];

export const FORM_SIGNUP_ARRAY = [ // %w()
  FORM_SIGNUP_AFFINIPAY,
  FORM_SIGNUP_ASSOCIATIONS,
  FORM_SIGNUP_CLIENTPAY,
  FORM_SIGNUP_CPACHARGE,
  FORM_SIGNUP_LAWPAY,
  FORM_SIGNUP_MYCASE,
];

export const AFFINIPAY_FORM_ARRAY = [
  FORM_CONTACT_AFFINIPAY,
  FORM_SIGNUP_AFFINIPAY,
  FORM_SIGNUP_ASSOCIATIONS,
];

export const LAWPAY_FORM_ARRAY = [
  FORM_DEMO_LAWPAY,
  FORM_DEMO_LAWPAY_PRO,
  FORM_DEMO_LAWPAY_ENTERPRISE,
  FORM_SIGNUP_LAWPAY,
  FORM_CONTACT_LAWPAY,
  FORM_CONTACT_LAWPAY_ENTERPRISE,
  FORM_CONTACT_LAWPAY_PARTNER,
  FORM_REFERRAL_LAWPAY,
  FROM_COST_COMPARE_LAWPAY,
];

export const CPACHARGE_FORM_ARRAY = [
  FORM_DEMO_CPACHARGE,
  FORM_SIGNUP_CPACHARGE,
  FORM_REFERRAL_CPACHARGE,
  FORM_CONTACT_CPACHARGE,
];

export const CLIENTPAY_FORM_ARRAY = [
  FORM_CONTACT_CLIENTPAY,
  FORM_CONTACT_CLIENTPAY_PARTNER,
  FORM_DEMO_CLIENTPAY,
  FORM_REFERRAL_CLIENTPAY,
  FORM_SIGNUP_CLIENTPAY,
];

export const MYCASE_FORM_ARRAY = [
  FORM_TRIAL_MYCASE,
  FORM_SIGNUP_MYCASE,
  FORM_CONTACT_MYCASE,
  FORM_DEMO_MYCASE,
];

export const CASEPEER_FORM_ARRAY = [
  FORM_DEMO_CASEPEER,
];

export const DOCKETWISE_FORM_ARRAY = [
  FORM_DEMO_DOCKETWISE,
];

export const FORM_COST_COMPARE_ARRAY = [
  FROM_COST_COMPARE_LAWPAY,
];
