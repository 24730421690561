import {
  LAWPAY_FORM_ARRAY,
  CPACHARGE_FORM_ARRAY,
  CLIENTPAY_FORM_ARRAY,
  AFFINIPAY_FORM_ARRAY,
  MYCASE_FORM_ARRAY,
  CASEPEER_FORM_ARRAY,
  DOCKETWISE_FORM_ARRAY,
} from '../marketo-forms/forms-manifest';

const Introspect = {
  defaultsForBrand(brand = 'lawpay', fromUrl = false) {
    const inferredBrand = (brand === '' ? 'lawpay' : brand);
    const brandDefaults = {
      partner: inferredBrand,
      partner_plan: `${inferredBrand}_monthly`,
      assignedByUrl: fromUrl,
    };
    return brandDefaults;
  },
  brandFromUrl(url = '') {
    const brandUrl = (window.location !== undefined) ? window.location.href : url;
    let brandData = Introspect.defaultsForBrand(brandUrl);

    if (brandUrl.includes('affinipay')) {
      brandData = Introspect.defaultsForBrand('affinipay', true);
    }

    if (brandUrl.includes('lawpay')) {
      brandData = Introspect.defaultsForBrand('lawpay', true);
    }

    if (brandUrl.includes('cpacharge')) {
      brandData = Introspect.defaultsForBrand('cpacharge', true);
    }

    if (brandUrl.includes('associations')) {
      brandData = Introspect.defaultsForBrand('affinipay', true);
    }

    if (brandUrl.includes('clientpay')) {
      brandData = Introspect.defaultsForBrand('clientpay', true);
    }

    if (brandUrl.includes('mycase')) {
      brandData = Introspect.defaultsForBrand('mycase', true);
    }

    if (brandUrl.includes('casepeer')) {
      brandData = Introspect.defaultsForBrand('casepeer', true);
    }

    if (brandUrl.includes('docketwise')) {
      brandData = Introspect.defaultsForBrand('docketwise', true);
    }

    return brandData;
  },
  brandFromFormId(formId) {
    let brandData = Introspect.defaultsForBrand();

    if (AFFINIPAY_FORM_ARRAY.includes(formId)) {
      brandData = Introspect.defaultsForBrand('affinipay', false);
    }

    if (LAWPAY_FORM_ARRAY.includes(formId)) {
      brandData = Introspect.defaultsForBrand('lawpay', false);
    }

    if (CPACHARGE_FORM_ARRAY.includes(formId)) {
      brandData = Introspect.defaultsForBrand('cpacharge', false);
    }

    if (CLIENTPAY_FORM_ARRAY.includes(formId)) {
      brandData = Introspect.defaultsForBrand('clientpay', false);
    }

    if (MYCASE_FORM_ARRAY.includes(formId)) {
      brandData = Introspect.defaultsForBrand('mycase', false);
    }

    if (CASEPEER_FORM_ARRAY.includes(formId)) {
      brandData = Introspect.defaultsForBrand('casepeer', false);
    }

    if (DOCKETWISE_FORM_ARRAY.includes(formId)) {
      brandData = Introspect.defaultsForBrand('docketwise', false);
    }
    return brandData;
  },
};

export default Introspect;
