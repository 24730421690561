<template>

  <div :ref="name" :class="inputGroupCss">
    <div class="label_wrap">
      <label>{{ label }}</label>
    </div>
    <div class="input_wrap">
      <DatePicker
        v-model="selectedDate"
        :disabledWeekDays="[0,6]"
        :weekStart=0
        :enableTimePicker="false"
        :minDate="firstAvailableDate"
        :maxDate="lastAvailableDate"
        :monthChangeOnScroll="false"
        :autoApply="true"
        placeholder="Select a Date"
        @update:modelValue="handleDateSelect"
        @blur="validate"
        :clearable="false"
      />
      <span class="error_message_text">{{ errorMessage }}</span>
    </div>
  </div>

</template>

<script>

import { ref } from 'vue';
import DatePicker from '@vuepic/vue-datepicker';
import { add } from 'date-fns';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'InputDatePicker',
  props: {
    label: { type: String, default: '' },
    name: { type: String, default: '' },
    rules: { type: [Object, String], default: 'required' },
    mode: { type: String, default: 'date' },
  },
  components: {
    DatePicker,
  },
  computed: {
    firstAvailableDate() {
      return new Date();
    },
    lastAvailableDate() {
      return add(new Date(), { days: 45 });
    },
    inputGroupCss() {
      return (this.$data.errorMessage !== '' ? 'input_group form-input-error' : 'input_group');
    },
  },
  data() {
    return {
      selectedDate: new Date(),
      errorMessage: '',
    };
  },
  emits: ['dateSelected'],
  methods: {
    handleDateSelect(date) {
      this.$data.selectedDate = date;
      this.$emit('dateSelected', date);
      this.validate();
    },
    validate() {
      const msg = (Object.prototype.toString.call(this.$data.selectedDate) === '[object Date]');
      this.$data.errorMessage = (msg === true ? '' : 'This field is required');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.ref = ref(this.$props);
      this.handleDateSelect(new Date());
    });
  },
};
</script>
