// import marOpsValidator from "./validation";

const ls = window.localStorage;
const ss = window.sessionStorage;

export const MopsUserInteractionEvent = (eventName = 'DEFAULT_MOPS_EVENT', data = {}) => {
  const mopsEvent = new CustomEvent(eventName, {
    detail: {
      event_name: eventName,
      timestamp: Date.now(),
      domain_name: window.location.host,
      page_path: window.location.pathname,
      formId: data.formId,
      referrer: document.referrer || 'direct',
      ab_variant: ls.getItem('ab_variant') || '',
      error: data.error || '',
      reference_number: data.form_request_id || '',
      mops_xid: ls.getItem('mops_xid') || '',
      utm_campaign: ls.getItem('utm_campaign') || '',
      utm_source: ls.getItem('utm_source') || '',
      utm_medium: ls.getItem('utm_medium') || '',
      utm_content: ls.getItem('utm_content') || '',
      utm_term: ls.getItem('utm_term') || '',
      partner: ss.getItem('partner') || 'unknown',
      partner_plan: ss.getItem('partner_plan') || 'unknown',
      sales_rep: ss.getItem('sales_rep') || ss.getItem('rep') || '',
      screen_width: window.screen.width || 0,
      screen_height: window.screen.height || 0,
      google_click_id: ss.getItem('google_click_id') || '',
      microsoft_click_id: ss.getItem('microsoft_click_id') || '',
      linkedin_click_id: ss.getItem('linkedin_click_id') || '',
      facebook_click_id: ss.getItem('facebook_click_id') || '',
      gpc_pref: ls.getItem('gpc_pref') || 0,
      dnt_pref: ls.getItem('dnt_pref') || 0,
    },
  });
  return mopsEvent;
};

export const GoogleTagManagerEvent = (eventName = 'DEFAULT_GTM_EVENT', data = {}) => {
  const gtmEvent = {
    event_name: eventName,
    timestamp: Date.now(),
    domain_name: window.location.host,
    page_path: window.location.pathname,
    ab_variant: data.ab_variant || ls.getItem('ab_variant') || '',
    // TODO:  Define which attributes to pass along to TagManager / GA4
  };
  return gtmEvent;
};
