// Marketing Website API

export const MARKETO_INSTANCE_ID = '404-TPA-276';

export const REGEX_ALLOWED_COOKIE_PREFIX = /(_){1}(fbp|fbc|g|mkto_trk|dc_gtm_){1}/;
export const REGEX_ALPHA_ONLY = /^[a-zA-Z\s]+$/;
export const REGEX_NUMERIC_ONLY = /^[0-9]+$/;
export const REGEX_COMPANY_NAME = /^([\s\-&a-z0-9]{3,40})+$/i;
export const REGEX_PERSON_NAME = /^([a-z\s]{2,20})+$/i;
export const REGEX_EMAIL_ADDRESS = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_PHONE_NUMBER = /^\({0,1}([2-9][0-9]{2})\){0,1}[-.\s]{0,1}([2-9][0-9]{2})[-.\s]{0,1}([0-9]{4})$/;
export const REGEX_GUID = /^([a-z0-9]{8})-([a-z0-9]{4})-([a-z0-9]{4})-([a-z0-9]{4})-([a-z0-9]{12})$/;

export const WHITELIST_DOMAINS = [
  'www.lawpay.com',
  'www.cpacharge.com',
  'www.clientpay.com',
  'www.affinipay.com',
  'www.affinipayassociations.com',
  'www.mycase.com',
  'www.casepeer.com',
  'www.docketwise.com',
  'www.woodpeckerweb.com',
  'www.medpay.com',
  'www.soluno.legal',
  'www.getbacktolawyering.com',
  'lp.lawpay.com',
  'lp.cpacharge.com',
  'lp.clientpay.com',
  'lp.affinipay.com',
  'lp.affinipayassociations.com',
  'lp.mycase.com',
  'lp.docketwise.com',
  'lp.casepeer.com',
  'lp.woodpeckerweb.com',
  'lp.soluno.legal',
  'lp.medpay.com',
  'campaign.soluno.legal',
];

export const WHITELIST_QUERYSTRING_VARS = [
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'utm_content',
  'utm_term',
  'partner',
  'rep',
  'promo',
  'gclid',
  'fbclid',
  'li_fat_id',
  'msclkid',
  'mkt_tok',
  'dg',
];

export const SESSION_STORAGE_KEYS = [
  'partner',
  'promo',
  'sales_rep',
  'google_click_id',
  'facebook_click_id',
  'linkedin_click_id',
  'microsoft_click_id',
  'dg',
];

export const WEBSITE_API_BASE_ENDPOINT = () => {
  const validDomain = WHITELIST_DOMAINS.some((d) => window.location.host === d);
  return validDomain ? 'https://www.marops.affinipay.com' : 'https://qa-www.marops.affinipay.com';
};

const MarOpsConstants = {
  WEBSITE_API_BASE_ENDPOINT,
  REGEX_ALLOWED_COOKIE_PREFIX,
  REGEX_ALPHA_ONLY,
  REGEX_NUMERIC_ONLY,
  REGEX_EMAIL_ADDRESS,
  REGEX_PHONE_NUMBER,
  REGEX_GUID,
  WHITELIST_QUERYSTRING_VARS,
  SESSION_STORAGE_KEYS,
};
export default MarOpsConstants;
