<template>

  <div id="wrapperSignUpForm" class="m-4">

    <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="firstName"
        name="firstName"
        label="First Name"
        rules="required|firstName"
        @inputChanged="onInputChange"
      />
      <InputGroup
        ref="lastName"
        name="lastName"
        label="Last Name"
        rules="required|lastName"
        @inputChanged="onInputChange"
      />
    </div>

    <div v-if="this.$props.showPracticeSize" class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="company"
        name="company"
        label="Company Name"
        rules="required|company"
        @inputChanged="onInputChange"
      />
      <InputGroup
        ref="practiceSize"
        name="practiceSize"
        label="Practice Size"
        rules="required"
        @inputChanged="onInputChange"
      />
    </div>

    <div v-else class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="company"
        name="company"
        label="Company Name"
        rules="required|company"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2">
      <InputGroup
        ref="email"
        name="email"
        label="Email"
        rules="required|email"
        @inputChanged="onInputChange"
      />
      <InputPhone
        ref="phone"
        name="phone"
        label="Phone"
        rules="required|phone"
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <InputCheckbox
        ref="callForWalkthrough"
        name="call_for_walkthrough"
        label="I have questions, please have an expert contact me."
        @inputChanged="onInputChange"
      />
    </div>

    <div class="grid grid-cols-1 lg:gap-4 gap-2">
      <button
        :class="buttonStyle"
        @click="handleSubmitClick"
      >
        Submit Form
      </button>
    </div>

  </div>
</template>

<script>
import InputGroup from '@src/components/input-group.vue';
import InputPhone from '@src/components/input-phone.vue';
import InputCheckbox from '@src/components/input-checkbox.vue';

export default {
  name: 'SignUpLayoutDefault',
  props: {
    onInputChange: { type: Function, required: true },
    onSubmit: { type: Function, required: true },
    onError: { type: Function, required: true },
    buttonStyle: { type: String, required: false, default: 'btn btn-primary btn_submit affinipay' },
    showPracticeSize: { type: Boolean, required: false, default: false },
    showComments: { type: Boolean, required: false, default: false },
  },
  components: {
    InputGroup,
    InputPhone,
    InputCheckbox,
  },
  methods: {
    handleSubmitClick(event) {
      this.$props.onSubmit(event, this.$refs);
    },
  },
};

</script>
